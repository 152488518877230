// Redux
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// Reducers
import { appApi, marketingApi } from "./api";
import CacheManager from "../singletons/CacheManager";

const store = configureStore({
  reducer: {
    [appApi.reducerPath]: appApi.reducer,
    [marketingApi.reducerPath]: marketingApi.reducer
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(appApi.middleware).concat(marketingApi.middleware);
  },
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
export const AppCacheManager = new CacheManager(store, appApi)

if (typeof window !== 'undefined' && process.env.GATSBY_WINDOW_API) {
  // @ts-ignore
  window['RTK_STORE'] = store
}