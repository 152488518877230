// React/Gatsby
import React from "react";

// Reach
import { PageProps } from "gatsby";

const Layout = (props: PageProps) => {
  return (
    <div id="layout">
      <div className="flex flex-auto">{props.children}</div>
    </div>
  );
};

export default Layout;
