import { Sha256 } from "@aws-crypto/sha256-js";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
  FromCognitoIdentityPoolParameters,
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { SerializedError } from "@reduxjs/toolkit";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { SignatureV4 } from "@smithy/signature-v4";
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import queryString from "query-string";
import qs from 'qs'
import Logger from "../singletons/Logger";

export interface IModel {
  time: { created: string; updated: string };
}

export interface Timestamps {
  time: { created: string; updated: string };
}

export interface ResponseObject<T> {
  items: Array<T>
  count?: number
}

export interface QueryStringParameters {
  fields?: string | Array<string> | Record<string, any>;
  filter?: Record<string, any>;
  search?: string | null;
  order?: string;
  order_direction?: "ASC" | "DESC";
  limit?: number;
  offset?: number;
  with?: string | Array<string>;
  withCount?: boolean;
  recaptcha?: {
    token: string;
    action: string;
  };
  [key: string]: any;
}

const tags = [
  "Department",
  "User",
  "Company",
  "Unit",
  "UnitCategory",
  "Criterion",
  "TestimonyType",
  "Testimony",
  "KnowledgeType",
  "TestimonySignatory",
  "Verification",
  "Signatory",
  "DepartmentRole",
  "Module",
  "CompanyInvitation",
  "Person",
  "File",
  "Member",
  "Submission",
  "SubmissionHistory",
  "SubmissionCriterion",
  "CompanyRole",
  "SubmissionFeedback",
  "SubmissionApproval",
  "SubmissionInvalidation",
  "Worksite",
  "Responsibility",
  "EmploymentCompany",
  "Certificate",
  "CertificateMember",
  "Training",
  "TrainingMember",
  "ProductOfWork",
  "Descriptor",
  "Job",
  "JobDescription",
  "Appraisal",
  "ProductOfWorkFile",
  "Audit",
  "Industry"
] as const;

export type TagTypes = (typeof tags)[number];

const getIAMHeaders = async (input: {
  url: string;
  method: string;
  body?: any;
}) => {
  if (!process.env.GATSBY_API_GATEWAY) {
    throw new Error("No API Gateway configured");
  }

  if (!process.env.GATSBY_COGNITO_IDENTITY_POOL_ID) {
    throw new Error("No Identity Pool ID configured");
  }

  const host = process.env.GATSBY_API_GATEWAY.replace(/https?\:\/\//, "");

  const params: FromCognitoIdentityPoolParameters = {
    identityPoolId: process.env.GATSBY_COGNITO_IDENTITY_POOL_ID,
    client: new CognitoIdentityClient({ region: "eu-west-1" }),
  };

  const credentials = await fromCognitoIdentityPool(params)();

  const signer = new SignatureV4({
    credentials,
    sha256: Sha256,
    region: "eu-west-1",
    service: "execute-api",
  });

  const parsed = queryString.parseUrl(input.url);

  const request = new HttpRequest({
    method: input.method,
    body: input.body ? JSON.stringify(input.body) : null,
    hostname: host,
    path: parsed.url.replace(process.env.GATSBY_API_GATEWAY!, ""),
    // @ts-ignore. They are basically the same. Please shut up.
    query: parsed.query,
    headers: {
      "Content-Type": "application/json",
      host,
    },
  });

  return (await signer.sign(request)).headers;
};

const getJWTHeaders = async (
  session: CognitoUserSession
): Promise<Record<string, string>> => {
  return {
    Authorization: session.getIdToken().getJwtToken(),
    "Content-Type": "application/json",
  };
};

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError | string | undefined
) => {
  if (error === undefined) {
    return "Sorry, something went wrong with your query";
  }

  if (typeof error === "string") {
    return error;
  }

  if ("error" in error) {
    return error.error;
  }

  if ("data" in error && typeof error.data === "object" && error.data) {
    if ("message" in error.data && typeof error.data.message === "string") {
      return error.data.message;
    }

    if ("error" in error.data && typeof error.data.error === "string") {
      return error.data.error;
    }
  }

  if ("status" in error && error.status === 403) {
    return "You do not have permission to the requested resource";
  }

  if ("status" in error && error.status === 404) {
    return "The requested resource could not be found";
  }

  return "Sorry, something went wrong contacting our server";
};

const serialize = function (
  obj?: Record<string, string>,
  prefix?: string
): string {
  if (!obj) return "";

  var str = [], p;

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];

      if (Array.isArray(v)) {
        for (const value of v) {
          str.push(
            value !== null && typeof value === "object"
              ? serialize(value, k)
              : encodeURIComponent(k) + "[]=" + encodeURIComponent(value)
          );
        }
      }
      else {
        str.push(
          v !== null && typeof v === "object"
            ? serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
  }

  return str.join("&");
};

export const appApi = createApi({
  reducerPath: "appApi",
  tagTypes: tags,
  baseQuery: async (args: string | FetchArgs, api, extraOptions) => {
    Logger.debug("Request received", args, api, extraOptions);

    if (typeof args === "string") {
      args = {
        url: args,
      };
    }

    const query: QueryReturnValue<
      any | undefined,
      FetchBaseQueryError | SerializedError | undefined,
      { request?: Request; response?: Response }
    > = {
      data: undefined,
      error: undefined,
      meta: {
        request: undefined,
        response: undefined,
      },
    };

    let session: CognitoUserSession | null = null;

    for (const cookie of (document && document.cookie.split(";")) ?? []) {
      if (cookie.includes("APP_SESSION")) {
        const Username = cookie.split("=")[1];

        const user = new CognitoUser({
          Username,
          Pool: new CognitoUserPool({
            ClientId: `${process.env.GATSBY_COGNITO_CLIENT_ID}`,
            UserPoolId: `${process.env.GATSBY_COGNITO_USER_POOL_ID}`,
          }),
        });

        // This just helps ensure the below works correctly. Don't ask.
        user.getSession(() => {
          return;
        });

        // Cannot check for not-null on a function unless you declare a variable on its return value
        // This is because Typescript cannot be sure of the return value of the function unless it gets stored
        session = user.getSignInUserSession();
      }
    }

    try {
      const url = new URL(
        `${!session ? "public/" : ""}${args.url}`,
        process.env.GATSBY_API_GATEWAY
      );
      url.search = serialize(args.params);

      let headers: HeadersInit;
      if (!session) {
        headers = await getIAMHeaders({
          url: url.href,
          method: args.method ?? "GET",
          body: args.body,
        });
      } else {
        headers = await getJWTHeaders(session);
      }

      const request = new Request(url, {
        body: args.body ? JSON.stringify(args.body) : undefined,
        headers,
        method: args.method,
      });

      query.meta!.request = request.clone();

      Logger.debug("Firing request", request);

      const response = await fetch(request);

      Logger.debug("Request response", response);

      query.meta!.response = response.clone();

      try {
        const data = await response.json();

        if (response.status > 299) {
          query.error = {
            error: data.message ?? data.error ?? response.statusText,
            status: "FETCH_ERROR",
            data,
          };
        } else {
          query.data = data;
        }
      } catch (e) {
        if (e instanceof Error) {
          Logger.debug("JSON unwrap error", e.message);
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        Logger.error(e.message);

        query.error = {
          error: e.message,
          status: "FETCH_ERROR",
        };
      }
    }

    Logger.debug("Returning query", query);

    if (query.error) {
      Logger.debug(getErrorMessage(query.error));
    }

    return query;
  },
  endpoints: () => ({}),
});

const types = [
  '$eq', // equal (case-sensitive)
  '$eqi', // equal (case-insensitive)
  '$ne', // not equal
  '$nei', // not equal (case-insensitive)
  '$lt', // less than
  '$lte', // less than or equal to
  '$gt', // greater than
  '$gte', // greater than or equal to
  '$in', // included in an array
  '$nin', // not included in an array
  '$contains', // contains (case-sensitive)
  '$containsi', // contains (case-insensitive)
  '$notContains', // not contains (case-sensitive)
  '$notContainsi', // not contains (case-insensitive)
  '$null', // is null
  '$notNull', // is not null
  '$between', // is between
  '$startsWith', // starts with (case-sensitive)
  '$startsWithi', // starts with (case-insensitive)
  '$endsWith', // ends with (case-sensitive)
  '$endsWithi', // ends with (case-insensitive)
  '$or' // logical OR
] as const

export type StrapiFilterTypes = (typeof types)[number];

export interface BaseAttributes {
  createdAt: string;
  updatedAt: string;
  publishedAt: string | null;
}

export interface StrapiGetResult<T> {
  data: T extends (infer U)[] ? StrapiGetData<U>[] : StrapiGetData<T>
}

export interface StrapiGetData<T> {
  id: number,
  attributes: BaseAttributes & T,
  meta?: {
    availableLocales?: Array<string>
    pagination: {
      page: number,
      pageSize: number,
      pageCount: number,
      total: number
    }
    [key: string]: any
  }
}

type RecursiveRecord = {
  [key: string]: string | RecursiveRecord | Omit<StrapiQueryStringParameters, 'filters' | 'pagination'>;
};

export type StrapiFilterRecord = Record<string, Partial<Record<StrapiFilterTypes | string, string>>>
export type StrapiFiltersRecord = StrapiFilterRecord | Partial<Record<StrapiFilterTypes | string, StrapiFilterRecord | Array<StrapiFilterRecord>>>

export interface StrapiQueryStringParameters {
  populate?: RecursiveRecord | string
  fields?: Array<string>
  filters?: StrapiFiltersRecord
  locale?: string
  publicationState?: 'live' | 'preview'
  sort?: string | Array<string>
  pagination?: {
    page?: number;
    pageSize?: number;
    withCount?: boolean;
    start?: number;
    limit?: number;
  }
}

export interface StrapiMediaFormat {
  ext: string;
  hash: string;
  height: number;
  mime: string;
  name: string;
  path: null;
  size: number;
  sizeInBytes: number;
  url: string;
  width: number;
}

export interface StrapiMedia {
  alternativeText: string | null;
  caption: string | null;
  createdAt: string;
  ext: string;
  formats: Record<string, StrapiMediaFormat>;
  hash: string;
  height: number;
  mime: string;
  name: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: any; // You can specify a more specific type if available
  size: number;
  updatedAt: string;
  url: string;
  width: number;
}

const strapiTags = ['Pages', 'Metadata', 'Doc']

export type StrapiTagTypes = (typeof strapiTags)[number];

export const marketingApi = createApi({
  reducerPath: 'marketingApi',
  tagTypes: strapiTags,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.GATSBY_STRAPI_BASE_URL,
    prepareHeaders(headers, api) {
      headers.append('Authorization', `Bearer ${process.env.GATSBY_STRAPI_API_TOKEN}`)

      return headers
    },
    paramsSerializer(params) {
      return qs.stringify(params, { encodeValuesOnly: true })
    },
  }),
  endpoints: () => ({}),
})
