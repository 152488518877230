import { appApi, QueryStringParameters, TagTypes, Timestamps } from "../../api";

export const CompanyRoleNames = ["Admin", "Owner", "Standard", "Revoked"] as const;

export type CompanyRoles = (typeof CompanyRoleNames)[number];

export const CompanyRolePrecedence: Record<CompanyRoles, number> = {
  Owner: 0,
  Admin: 1,
  Standard: 2,
  Revoked: 999
};

export type CompanyRole = {
  id: string;
  name: "Admin" | "Owner" | "Standard" | "Revoked";
  precedence: number;
} & Timestamps;

export class CompanyRoleUtil {
  public static atLeast(precedence: number, comparison: number) {
    return precedence <= comparison;
  }

  public static atMost(precedence: number, comparison: number) {
    return precedence >= comparison;
  }
}

const companyRoleApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyRoles: build.query({
      query: ({ params }: { params?: QueryStringParameters }) => ({
        url: `company/role`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<CompanyRole>) {
        return baseQueryReturnValue
      },
      providesTags: (result) => {
        if (!result || result.length <= 0) {
          return ["CompanyRole"];
        }

        return result.map<{ type: TagTypes; id: string }>((item) => {
          return { type: "CompanyRole", id: item.id };
        });
      },
    }),
    getRoleByMember: build.query({
      query: ({
        memberId,
        params,
      }: {
        memberId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `member/${memberId}/role`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<CompanyRole>) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { memberId }) => {
        if (!result || result.length <= 0) {
          return ["DepartmentRole", "Member"];
        }

        return result
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "DepartmentRole", id: item.id };
          })
          .concat({ type: "Member", id: memberId });
      },
    }),
  }),
});

export const {
  useGetCompanyRolesQuery,
  useGetRoleByMemberQuery,
} = companyRoleApi;

export default companyRoleApi;
