// React/Gatsby
import React, { Dispatch, SetStateAction } from "react";

// Reach
import { Slice } from "gatsby";
import { useCookies } from "react-cookie";
import CompanyProvider from "../context/CompanyContext";
import DepartmentProvider, { DepartmentContext } from "../context/DepartmentContext";
import { SplashScreen } from "../components/common/SplashScreen";
import AuthContext, { AuthProvider } from "../context/AuthContext";

export interface AppContext {
  aside: boolean;
  setAside: (v: boolean) => void;
  keyboardListeners: Array<(e: KeyboardEvent) => void>;
  addKeyboardListener: (listener: (e: KeyboardEvent) => void) => void,
  removeKeyboardListener: (listener: (e: KeyboardEvent) => void) => void,
  mouseListeners: Array<(e: React.MouseEvent<HTMLElement, MouseEvent>) => void>;
  addMouseListener: (listener: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) => void,
  removeMouseListener: (listener: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) => void,
  page?: string;
  setPage: Dispatch<SetStateAction<string>>;
}

export const AppContext = React.createContext<AppContext>({
  aside: false,
  setAside: (v: boolean) => {},
  keyboardListeners: [],
  addKeyboardListener: (listener: (e: KeyboardEvent) => void) => {},
  removeKeyboardListener: (listener: (e: KeyboardEvent) => void) => {},
  mouseListeners: [],
  addMouseListener: (listener: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {},
  removeMouseListener: (listener: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {},
  setPage(s) {
    return;
  },
});

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const [aside, setAside] = React.useState(
    typeof window !== 'undefined' && window.innerWidth <= 480 ? false : true
  );
  const [mouseListeners, setMouseListeners] = React.useState<
    Array<(e: React.MouseEvent<HTMLElement, MouseEvent>) => void>
  >([]);
  const [keyboardListeners, setKeyboardListeners] = React.useState<
    Array<(e: KeyboardEvent) => void>
  >([]);
  const [page, setPage] = React.useState("Home");
  const [cookies] = useCookies([
    "APP_SESSION",
    "APP_DEPARTMENT",
  ]);

  const onAppClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    for (const listener of mouseListeners) {
      listener(e);
    }
  };

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      for (const listener of keyboardListeners) {
        listener(e);
      }
    }

    document.addEventListener('keydown', listener)

    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [keyboardListeners])


  // FIXME: Temporary fix for hydration issues with React.
  // This will initially show a Splashscreen on both Server and Client, preventing the hydration issue from React.
  // It's a hack, but the problem is non-trivial and this is fine for now (requires all inner pages to be consistent with server on initial load).
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Consumer>
      {({ user }) => {
        if ((!!cookies.APP_SESSION && !user?.getSignInUserSession())) {
          return <SplashScreen text="Authenticating..." />;
        }

        return (
          <DepartmentContext.Consumer>
            {({ department, person }) => {
              if (
                ((!department && cookies.APP_DEPARTMENT) ||
                (department && !person))
              ) {
                return (
                  <SplashScreen text="Loading your data for the chosen department..." />
                );
              }

              return (
                <AppContext.Provider
                  value={{
                    aside,
                    setAside,
                    mouseListeners,
                    addMouseListener(listener) {
                      setMouseListeners([...mouseListeners, listener])
                    },
                    removeMouseListener(listener) {
                      const index = mouseListeners.findIndex((l) => l === listener)
                      const tmp = mouseListeners.slice()
                      tmp.splice(index, 1)

                      setMouseListeners(tmp)
                    },
                    keyboardListeners,
                    addKeyboardListener(listener) {
                      setKeyboardListeners([...keyboardListeners, listener])
                    },
                    removeKeyboardListener(listener) {
                      const index = keyboardListeners.findIndex((l) => l === listener)
                      const tmp = keyboardListeners.slice()
                      tmp.splice(index, 1)

                      setKeyboardListeners(tmp)
                    },
                    page,
                    setPage,
                  }}
                >
                  <main
                    id="app-layout"
                    className="flex flex-col flex-auto w-screen h-screen overflow-hidden"
                    onClick={(e) => onAppClick(e)}
                  >
                    <Slice alias="app-header" />

                    <div className="flex flex-1 overflow-auto relative">
                      <Slice alias="app-aside" />

                      {children}
                    </div>
                  </main>
                </AppContext.Provider>
              )
            }}
          </DepartmentContext.Consumer>
        )
      }}
    </AuthContext.Consumer>
  );
};

export default AppLayout;
