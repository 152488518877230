import { RootNode } from "@strapi/blocks-react-renderer/dist/BlocksRenderer";
import { StrapiGetResult, StrapiMedia, StrapiQueryStringParameters, marketingApi } from "../../api";

export interface Metadata {
  Short_Description: string,
  Name: string,
  Tagline: string
}

export interface StrapiLinkComponent {
  href: string,
  id: number,
  title: string
}

export interface StrapiSplitScreenSection {
  description?: RootNode[];
  id?: number;
  title?: string;
  image_video?: Partial<StrapiGetResult<StrapiMedia>>;
  link?: StrapiLinkComponent
}

export interface StrapiFeature {
  single_media?: Partial<StrapiGetResult<StrapiMedia>>,
  title?: string
  description?: RootNode[]
}

export interface StrapiPerson {
  name?: string,
  image?: Partial<StrapiGetResult<StrapiMedia>>,
  description?: RootNode[]
  email?: string
}

export interface Homepage {
  call_to_action_text: string,
  features_title: string,
  hero_video_image: Partial<StrapiGetResult<StrapiMedia>>,
  split_screens: Array<StrapiSplitScreenSection>,
  features: Array<StrapiFeature>
}

export interface AboutUsPage {
  title: string,
  subtitle: string,
  header_media: Partial<StrapiGetResult<StrapiMedia>>,
  about_us: string,
  about_us_title: string,
  people: Array<StrapiPerson>
}

const pagesApi = marketingApi.injectEndpoints({
  endpoints: (build) => ({
    getMetadata: build.query<StrapiGetResult<Metadata>, { params?: StrapiQueryStringParameters }>({
      query: ({ params }: { params: StrapiQueryStringParameters }) => ({
        url: 'metadata',
        params
      }),
      providesTags: (result, err) => {
        if (!result) {
          return ["Metadata"];
        }

        return [{ type: "Metadata", id: result?.data.id }]
      },
    }),
    getHomepage: build.query<StrapiGetResult<Homepage>, { params?: StrapiQueryStringParameters }>({
      query: ({
        params,
      }) => ({
        method: 'GET',
        url: 'homepage',
        params,
      }),
      providesTags: (result, err) => {
        if (!result) {
          return ["Pages"];
        }

        return [{ type: "Pages", id: 'homepage' }]
      },
    }),
    getAboutUsPage: build.query<StrapiGetResult<AboutUsPage>, { params?: StrapiQueryStringParameters }>({
      query: ({
        params,
      }) => ({
        method: 'GET',
        url: 'about-us',
        params,
      }),
      providesTags: (result, err) => {
        if (!result) {
          return ["Pages"];
        }

        return [{ type: "Pages", id: 'homepage' }]
      },
    }),
  }),
});

export const {
  useGetMetadataQuery,
  useGetHomepageQuery,
  useGetAboutUsPageQuery
} = pagesApi;

export default pagesApi;
