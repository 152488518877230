import { AppCacheManager } from "../..";
import { appApi, QueryStringParameters, ResponseObject, TagTypes, Timestamps } from "../../api";
import { CertificateMember } from "../member/certificate";
import { User } from "../user";
import { Company } from "./company";
import { EmploymentCompany } from "./employmentCompany";
import { Job } from "./job/job";
import { Responsibility } from "./responsibility";
import { CompanyRole } from "./role";
import { TrainingMember } from "./training";
import { Worksite } from "./worksite";

export type Member = {
  id: string;
  userId: string;
  companyId: string;
  roleId: string;
  worksiteId: string | null;
  employmentCompanyId: string | null;
  user: User;
  role: CompanyRole;
  company: Company;
  jobId?: string | null;
  employmentNumber: number;
  joinedAt: string
  contractType: 'staff' | 'contracted'
  worksite: Worksite
  responsibilities?: Responsibility[]
  job?: Job
  employmentCompany: EmploymentCompany
  trainingMember: TrainingMember[]
  certificateMember: CertificateMember[]
} & Timestamps;

export type MemberBody = Partial<
  Omit<
    Member,
    "id" | "userId" | "companyId" | "user" | "role" | "company" | "time"
  >
>;

const memberApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getMemberById: build.query({
      async queryFn(arg, api, extraOptions, baseQuery) {
        if (!arg.params) {
          const data = AppCacheManager.findById(arg.memberId, 'Member')

          if (data) {
            return { data }
          }
        }

        return baseQuery({
          url: `member/${arg.memberId}`,
          method: "GET",
          params: arg.params,
        })
      },
      providesTags: (result, err, { memberId }) => {
        return [{ type: "Member", id: result?.id }];
      },
    }),
    getMembersByCompany: build.query<ResponseObject<Member>, { companyId: string, params?: QueryStringParameters }>({
      query: ({
        companyId,
        params,
      }: {
        companyId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `company/${companyId}/member`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { companyId }) => {
        return (result?.items ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "Member", id: item.id };
        }).concat([{ type: 'Member', id: companyId }]);
      },
    }),
    getMembersByWorksite: build.query({
      query: ({
        worksiteId,
        params,
      }: {
        worksiteId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `worksite/${worksiteId}/member`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Member>) {
        return baseQueryReturnValue
      },
      providesTags: (result, err, { worksiteId }) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "Member", id: item.id }
        }).concat([{ type: 'Member', id: worksiteId }]);
      },
    }),
    getMembersByJob: build.query({
      query: ({
        jobId,
        params,
      }: {
        jobId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `job/${jobId}/member`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Member>) {
        return baseQueryReturnValue
      },
      providesTags: (result, err, { jobId }) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "Member", id: item.id }
        }).concat([{ type: 'Member', id: jobId }]);
      },
    }),
    getMembersByResponsibility: build.query({
      query: ({
        responsibilityId,
        params,
      }: {
        responsibilityId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `responsibility/${responsibilityId}/member`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Member>) {
        return baseQueryReturnValue
      },
      providesTags: (result, err, { responsibilityId }) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "Member", id: item.id }
        }).concat([{ type: 'Member', id: responsibilityId }]);
      },
    }),
    getMembersByTraining: build.query({
      query: ({
        trainingId,
        params,
      }: {
        trainingId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `training/${trainingId}/member`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Member>) {
        return baseQueryReturnValue
      },
      providesTags: (result, err, { trainingId }) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "Member", id: item.id }
        }).concat([{ type: 'Member', id: trainingId }]);
      },
    }),
    getMembersByCertificate: build.query({
      query: ({
        certificateId,
        params,
      }: {
        certificateId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `certificate/${certificateId}/member`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Member>) {
        return baseQueryReturnValue
      },
      providesTags: (result, err, { certificateId }) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "Member", id: item.id }
        }).concat([{ type: 'Member', id: certificateId }]);
      },
    }),
    getMembersByEmploymentCompany: build.query({
      query: ({
        employmentCompanyId,
        params,
      }: {
        employmentCompanyId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `employment-company/${employmentCompanyId}/member`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Member>) {
        return baseQueryReturnValue
      },
      providesTags: (result, err, { employmentCompanyId }) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "Member", id: item.id }
        }).concat([{ type: 'Member', id: employmentCompanyId }]);
      },
    }),
    updateMember: build.mutation({
      query: ({ memberId, body }: { memberId: string; body: MemberBody }) => ({
        url: `member/${memberId}`,
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue: Member) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({ memberId }, api) {
        try {
          const { data } = await api.queryFulfilled

          if (data.jobId) {
            const original = AppCacheManager.findById<Member>(memberId, 'Member')

            if (original?.jobId && original.jobId !== data.jobId) {
              AppCacheManager.delete({ id: data.id, tags: [{ type: 'Member', id: original.jobId }] })
              AppCacheManager.create({ body: data, tags: [{ type: 'Member', id: data.jobId }] })
            }
          }

          AppCacheManager.update({ id: memberId, body: data, tags: [{ type: 'Member', id: memberId }] })
        } catch {}
      },
    }),
    restoreMember: build.mutation({
      query: ({ memberId }: { memberId: string }) => ({
        url: `member/${memberId}/restore`,
        method: "POST",
      }),
      transformResponse(baseQueryReturnValue: Member) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({ memberId }, api) {
        try {
          const { data } = await api.queryFulfilled

          AppCacheManager.update({ id: memberId, body: data, tags: [{ type: 'Member', id: memberId }] })
        } catch {}
      },
    }),
    revokeMember: build.mutation({
      query: ({ memberId }: { memberId: string }) => ({
        url: `member/${memberId}/revoke`,
        method: "POST",
      }),
      transformResponse(baseQueryReturnValue: Member) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({ memberId }, api) {
        try {
          const { data } = await api.queryFulfilled

          AppCacheManager.update({ id: memberId, body: data, tags: [{ type: 'Member', id: memberId }] })
        } catch {}
      },
    }),
  }),
});

export const {
  useGetMemberByIdQuery,
  useGetMembersByCompanyQuery,
  useUpdateMemberMutation,
  useRevokeMemberMutation,
  useRestoreMemberMutation,
  useGetMembersByWorksiteQuery,
  useGetMembersByResponsibilityQuery,
  useGetMembersByJobQuery,
  useGetMembersByEmploymentCompanyQuery,
  useGetMembersByCertificateQuery,
  useGetMembersByTrainingQuery
} = memberApi;

export default memberApi;
