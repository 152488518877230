import { appApi, QueryStringParameters, TagTypes, Timestamps } from "../../api";
import { Module } from "../module";
import { Person } from "./person";
import { Unit } from "../units";
import { Company } from "../company/company";

export type Department = {
  id: string;
  companyId: string;
  name: string;
  function: string;
  description?: string;
  company: Company;
  modules?: Array<Module>;
  people?: Array<Person>;
  units?: Array<Unit>;
} & Timestamps;

export type DepartmentBody = Partial<
  Omit<
    Department,
    "companyId" | "company" | "modules" | "people" | "units" | "time"
  >
>;

const departmentApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getDepartments: build.query({
      query: ({ params }: { params?: QueryStringParameters }) => ({
        url: `department`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Department>) {
        return baseQueryReturnValue;
      },
      providesTags: (result) =>
        result && result.length > 0
          ? result.map((item) => {
              return { type: "Department", id: item.id };
            })
          : ["Department"],
    }),
    getDepartmentsByCompany: build.query({
      query: ({ companyId, params }: { companyId: string, params?: QueryStringParameters }) => ({
        url: `company/${companyId}/department`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Department>) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { companyId }) => {
        if (!result || result.length <= 0) {
          return ["Department", "Member"];
        }

        return result
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Department", id: item.id };
          })
          .concat({ type: "Company", id: companyId });
      },
    }),
    getDepartmentsByMember: build.query({
      query: ({
        memberId,
        params,
      }: {
        memberId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `member/${memberId}/department`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Department>) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { memberId }) => {
        if (!result || result.length <= 0) {
          return ["Department", "Member"];
        }

        return result
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Department", id: item.id };
          })
          .concat({ type: "Member", id: memberId });
      },
    }),
    getDepartment: build.query({
      query: ({
        id,
        params,
      }: {
        id: string;
        params?: QueryStringParameters;
      }) => ({
        url: `department/${id}`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Department) {
        return baseQueryReturnValue;
      },
      providesTags: (result) => {
        if (!result) {
          return ["Department"];
        }

        return [{ type: "Department", id: result.id }];
      },
    }),
    addMemberToDepartment: build.mutation({
      query: ({
        departmentId,
        memberId,
        roleId,
      }: {
        departmentId: string;
        memberId: string;
        roleId?: string;
      }) => ({
        url: `department/${departmentId}/people`,
        method: "POST",
        body: {
          roleId,
          memberId,
        },
      }),
      transformResponse(baseQueryReturnValue: Person) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (res, err, { departmentId }) => [
        { type: "Person", id: res?.id },
        { type: "Department", id: departmentId },
      ],
    }),
    createDepartmentByCompany: build.mutation({
      query: ({
        companyId,
        body,
      }: {
        companyId: string;
        body: DepartmentBody;
      }) => ({
        url: `company/${companyId}/department`,
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue: Department) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Department"],
    }),
    updateDepartment: build.mutation({
      query: ({ id, body }: { id: string; body: DepartmentBody }) => ({
        url: `department/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue: Department) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Department", id }],
    }),
    deleteDepartment: build.mutation({
      query: (id: string) => ({
        url: `department/${id}`,
        method: "DELETE",
      }),
      transformResponse(baseQueryReturnValue: Department) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result) => [{ type: "Department", id: result?.id }],
    }),
  }),
});

export const {
  useGetDepartmentQuery,
  useCreateDepartmentByCompanyMutation,
  useDeleteDepartmentMutation,
  useUpdateDepartmentMutation,
  useLazyGetDepartmentsQuery,
  useAddMemberToDepartmentMutation,
  useGetDepartmentsByMemberQuery,
  useGetDepartmentsByCompanyQuery
} = departmentApi;

export default departmentApi;
