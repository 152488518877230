import { AppCacheManager } from "../..";
import { appApi, QueryStringParameters, TagTypes, Timestamps } from "../../api";
import { Department } from "../department/department";
import { CompanyInvitation } from "./invitation";
import { Member } from "./member";
import { Signatory } from "./signatory";

export type Company = {
  id: string;
  name: string;
  members?: Array<Member>;
  departments?: Array<Department>;
  signatories?: Array<Signatory>;
  invitations?: Array<CompanyInvitation>;
} & Timestamps;

export type CompanyBody = Partial<
  Omit<
    Company,
    "id" | "members" | "departments" | "signatories" | "invitations" | "time"
  >
>;

const companyApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getCompany: build.query({
      query: ({
        companyId,
        params,
      }: {
        companyId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `company/${companyId}`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Company) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { companyId }) => [
        { type: "Company", id: result?.id },
      ],
    }),
    getCompanies: build.query({
      query: ({
        params,
      }: {
        params?: QueryStringParameters;
      }) => ({
        url: `company`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Company>) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err) => {
        return (result ?? [])
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Company", id: item.id };
          })
      },
    }),
    getCompaniesByUser: build.query({
      query: ({
        userId,
        params,
      }: {
        userId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `user/${userId}/company`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<Company>) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { userId }) => {
        return (result ?? [])
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Company", id: item.id };
          })
          .concat({ type: "Company", id: userId });
      },
    }),
    joinCompany: build.mutation<
      { company: Company; member: Member },
      { companyId: string, userId: string }
    >({
      query: ({ companyId }) => ({
        url: `company/${companyId}/join`,
        method: "POST",
      }),
      // invalidatesTags: (result, error, { companyId }) => ["Company"],
      async onQueryStarted(arg, api) {
        try {
          const { data } = await api.queryFulfilled

          AppCacheManager.create({ body: data, tags: [{ type: 'Company', id: arg.userId }] })
        } catch {}
      },
    }),
    addUserToCompany: build.mutation<Member, { companyId: string, userId: string }>({
      query: ({
        companyId,
        userId,
      }) => ({
        url: `company/${companyId}/user/${userId}`,
        method: "PUT",
      }),
      // This returns a company member, not a user.
      async onQueryStarted(arg, api) {
        try {
          const { data } = await api.queryFulfilled

          AppCacheManager.create({ body: data, tags: [{ type: 'Member', id: arg.companyId }] })
        } catch {}
      },
    }),
    createCompany: build.mutation({
      query: ({
        body,
      }: {
        body: CompanyBody;
      }) => ({
        url: `company`,
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue: Company) {
        return baseQueryReturnValue;
      },
      async onQueryStarted(arg, api) {
        try {
          const { data } = await api.queryFulfilled

          AppCacheManager.create({ body: data, tags: ['Company'] })
        } catch {}
      },
    }),
    updateCompany: build.mutation({
      query: ({
        companyId,
        body,
      }: {
        companyId: string;
        body: CompanyBody;
      }) => ({
        url: `company/${companyId}`,
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue: Company) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({ companyId }, api) {
        try {
          const { data } = await api.queryFulfilled

          AppCacheManager.update({ id: companyId, body: data, tags: ['Company'] })
        } catch {}
      },
    }),
    deleteCompany: build.mutation({
      query: ({
        companyId,
      }: {
        companyId: string;
      }) => ({
        url: `company/${companyId}`,
        method: "DELETE",
      }),
      transformResponse(baseQueryReturnValue: Company) {
        return baseQueryReturnValue;
      },
      async onQueryStarted({ companyId }, api) {
        try {
          await api.queryFulfilled

          AppCacheManager.delete({ id: companyId, tags: ['Company'] })
        } catch {}
      },
    }),
  }),
});

export const {
  useGetCompaniesByUserQuery,
  useGetCompanyQuery,
  useLazyGetCompaniesByUserQuery,
  useLazyGetCompanyQuery,
  useAddUserToCompanyMutation,
  useUpdateCompanyMutation,
  useJoinCompanyMutation,
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation
} = companyApi;

export default companyApi;
