import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData,
  UserData,
} from "amazon-cognito-identity-js";
import React from "react";
import { useCookies } from "react-cookie";
import { appApi } from "../store/api";
import { useAppDispatch } from "../store";
import { SplashScreen } from "../components/common/SplashScreen";
import Logger from "../singletons/Logger";
import dayjs from "dayjs";
import toast from "react-hot-toast";

export class AuthUser extends CognitoUser {
  private listeners = {} as any;

  public static async onEntryAuthenticate(
    pool: ICognitoUserPoolData
  ): Promise<AuthUser> {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      if (cookie.includes("APP_SESSION")) {
        const Username = cookie.split("=")[1];

        const user = new AuthUser({
          Username,
          Pool: new CognitoUserPool(pool),
        });

        return await new Promise<AuthUser>((resolve) => {
          user.getSession((err: Error | null, session: CognitoUserSession) => {
            if (err) {
              return resolve(
                new AuthUser({
                  Username: "",
                  Pool: new CognitoUserPool(pool),
                })
              );
            }

            user.refreshSession(session.getRefreshToken(), (err, result) => {
              if (err) {
                return resolve(
                  new AuthUser({
                    Username: "",
                    Pool: new CognitoUserPool(pool),
                  })
                );
              }

              resolve(user);
            });
          });
        });
      }
    }

    return new AuthUser({
      Username: "",
      Pool: new CognitoUserPool(pool),
    });
  }

  public listen(to: string, listener: Function) {
    if (!(to in this.listeners)) {
      this.listeners[to] = [] as Array<Function>;
    }

    this.listeners[to].push(listener);
  }

  public ignore(to: string, listener: Function) {
    if (!(to in this.listeners)) {
      console.warn(`${to} does not exist as a listener group`);

      return;
    }

    this.listeners[to].splice(
      this.listeners[to].findIndex((l: Function) => l === listener),
      1
    );
  }

  public signOut(callback?: (() => void) | undefined): void {
    super.signOut(callback);

    if ("signOut" in this.listeners) {
      for (const listener of this.listeners["signOut"]) {
        listener(this);
      }
    }
  }
}

export interface IAuthContext {
  user?: AuthUser;
  setUser: (user: AuthUser) => void;
  userData?: UserData;
  setUserData: (data?: UserData) => void;
  isLoggedIn: () => boolean;
  login: ({ remember, userId }: { remember: boolean; userId: string }) => void;
  logout: () => void;
}

const AuthContext = React.createContext<IAuthContext>({
  setUser(user) {
    return;
  },
  setUserData(data) {
    return;
  },
  isLoggedIn() {
    return false;
  },
  login({ remember, userId }) {
    return;
  },
  logout() {
    return;
  },
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  const [user, setUser] = React.useState(
    new AuthUser({
      Username: "",
      Pool: new CognitoUserPool({
        ClientId: process.env.GATSBY_COGNITO_CLIENT_ID!,
        UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID!,
      }),
    })
  );
  const [userData, setUserData] = React.useState<UserData | undefined>(
    undefined
  );

  const [cookies, setCookie, removeCookie] = useCookies([
    "APP_SESSION",
    "APP_DEPARTMENT",
    "APP_COMPANY",
  ]);

  const isLoggedIn = () => {
    return !!(user && user.getSignInUserSession());
  };

  const login = ({
    remember,
    userId,
  }: {
    remember: boolean;
    userId: string;
  }) => {
    dispatch(appApi.util.resetApiState());

    setCookie("APP_SESSION", userId, {
      path: "/",
      expires: remember ? dayjs().add(7, "day").toDate() : undefined,
    });

    toast.success("Successfully logged in");
  };

  const logout = () => {
    Logger.debug("User signed out. Resetting application state.");

    removeCookie("APP_SESSION", { path: "/" });
    removeCookie("APP_COMPANY", { path: "/" });
    removeCookie("APP_DEPARTMENT", { path: "/" });

    user.signOut();

    dispatch(appApi.util.resetApiState());
  };

  React.useEffect(() => {
    Logger.debug("APP_SESSION cookie use effect", cookies.APP_SESSION);

    if (cookies.APP_SESSION) {
      (async () => {
        const user = await AuthUser.onEntryAuthenticate({
          ClientId: process.env.GATSBY_COGNITO_CLIENT_ID!,
          UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID!,
        });

        if (user.getSignInUserSession()) {
          Logger.debug("User is signed in");

          setUserData(
            await new Promise<UserData | undefined>((resolve, reject) => {
              Logger.debug("Setting user data");

              user.getUserData((err, result) => {
                if (err) {
                  reject(err);
                }

                resolve(result);
              });
            })
          );

          const onWindowFocus = () => {
            if (user.getSignInUserSession()) {
              user?.getSession(
                (err: Error | null, result: CognitoUserSession) => {
                  if (err) {
                    logout();
                  }

                  if (result && !result.isValid()) {
                    // FIXME: Think this is not working correctly.
                    user?.refreshSession(result.getRefreshToken(), (err) => {
                      if (err) {
                        logout();
                      }
                    });
                  }
                }
              );
            }
          };

          window.addEventListener("focus", onWindowFocus);

          setUser(user);

          return function cleanup() {
            window.removeEventListener("focus", onWindowFocus);
          };
        } else {
          Logger.debug("User not signed in. Removing cookie");

          removeCookie("APP_SESSION", { path: "/" });
        }
      })();
    }
  }, [cookies.APP_SESSION]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userData,
        setUserData,
        isLoggedIn,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
