// React
import React from 'react'

// Redux
import { Provider } from 'react-redux'
import store from './src/store/index'

// Vendor/Other
import { Flowbite } from 'flowbite-react'
import { Toaster } from 'react-hot-toast'

// Components
import Layout from './src/layout/default'
import DynamicLayout from './src/layout/dynamic'

// CSS
import { AnimatePresence } from "framer-motion"
import { CookiesProvider } from 'react-cookie'
import { RecoilRoot } from 'recoil'
import { AuthProvider } from './src/context/AuthContext'
import FontAwesomePreloader from './src/context/FontAwesomePreloader'
import './src/styles/global.css'
import './src/styles/sass/global.scss'

// DayJS
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import CompanyProvider from './src/context/CompanyContext'
import DepartmentProvider from './src/context/DepartmentContext'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>
    <Provider store={store}>
      <CookiesProvider>
        <FontAwesomePreloader>
          {element}
        </FontAwesomePreloader>
      </CookiesProvider>
    </Provider>
  </RecoilRoot>
)

export const wrapPageElement = ({ element, props }) => (
  <div id="app">
    <Layout {...props}>
      <AnimatePresence initial={false} mode='wait' onExitComplete={() => window.scrollTo && window.scrollTo(0, 0)}>
        <Flowbite>
          <AuthProvider>
            <CompanyProvider>
              <DepartmentProvider>
                <DynamicLayout {...props}>
                  {element}
                </DynamicLayout>
              </DepartmentProvider>
            </CompanyProvider>
          </AuthProvider>
        </Flowbite>
      </AnimatePresence>
    </Layout>

    <Toaster position='bottom-right' />
  </div>
)