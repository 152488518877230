import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore"
import { Api } from "@reduxjs/toolkit/dist/query"
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks"
import { TagTypes } from "../store/api"
import Logger from "./Logger"
import _ from "lodash"

class CacheManager {
  store: ToolkitStore
  api: Api<any, any, any, any, any>

  constructor (store: ToolkitStore, api: Api<any, any, any, any, any>) {
    this.store = store
    this.api = api
  }

  findById<T> (id: string, tag: TagTypes) {
    const tagEndpoints = this.store.getState()[this.api.reducerPath].provided[tag]

    if (tagEndpoints && id in tagEndpoints) {
      for (const endpoint of tagEndpoints[id]) {
        const response = this.store.getState()[this.api.reducerPath].queries[endpoint]
        const data = response.data

        if (Array.isArray(data)) {
          for (const item of data) {
            if (item && typeof item === 'object' && 'id' in item && item.id === id) {
              return item as T
            }
          }
        } else if (data && typeof data === 'object') {
          if ('id' in data && data.id === id) {
            return data as T
          }

          if ('items' in data && Array.isArray(data.items)) {
            return data.items.find((d: any) => d.id === id) as T
          }
        }
      }
    }
  }

  create({ body, tags }: { body?: Record<string, any>, tags: Array<TagTypes> | Array<{ type: TagTypes, id?: string}> }) {
    const patchResults: PatchCollection[] = []

    try {
      const entries = this.api.util.selectInvalidatedBy(this.store.getState(), tags)
      for (const entry of entries) {
        const originalData = this.store.getState()[this.api.reducerPath].queries[entry.queryCacheKey]

        if (Array.isArray(originalData.data)) {
          this.store.dispatch(
            this.api.util.upsertQueryData(entry.endpointName, entry.originalArgs, [body, ...originalData.data])
          )
        }

        if ('items' in originalData.data && Array.isArray(originalData.data.items)) {
          this.store.dispatch(
            this.api.util.upsertQueryData(entry.endpointName, entry.originalArgs, { ...originalData, items: [ body, ...originalData.data.items ], count: originalData.data.count + 1 })
          )
        }
      }
    } catch (e: unknown) {
      Logger.debug('Failed to update cache entries', e)

      patchResults.forEach((result) => result.undo())
    }

    return patchResults
  }

  update({ id, body, tags }: { id: string, body: Record<string, any>, tags: Array<TagTypes> | Array<{ type: TagTypes, id?: string}> }) {
    const patchResults: PatchCollection[] = []

    try {
      const entries = this.api.util.selectInvalidatedBy(this.store.getState(), tags)
      for (const entry of entries) {
        patchResults.push(
          this.store.dispatch(
            this.api.util.updateQueryData(entry.endpointName, entry.originalArgs, (draft: any) => {
              if (draft) {
                if (Array.isArray(draft)) {
                  for (let item of draft) {
                    if (item.id === id) {
                      Object.assign(item, body)
                    }
                  }
                } else if (draft.items) {
                  for (let item of draft.items) {
                    if (item.id === id) {
                      Object.assign(item, body)
                    }
                  }
                } else if (draft) {
                  Object.assign(draft, body)
                }
              }

              return draft
            })
          )
        )
      }
    } catch (e: unknown) {
      Logger.debug('Failed to update cache entries', e)

      patchResults.forEach((result) => result.undo())
    }

    return patchResults
  }

  delete({ id, tags }: { id: string, tags: Array<TagTypes> | Array<{ type: TagTypes, id?: string}> }) {
    const patchResults: PatchCollection[] = []

    try {
      const entries = this.api.util.selectInvalidatedBy(this.store.getState(), tags)

      for (const entry of entries) {
        patchResults.push(
          this.store.dispatch(
            this.api.util.updateQueryData(entry.endpointName, entry.originalArgs, (draft: any) => {
              if (draft) {
                if (Array.isArray(draft)) {
                  draft = draft.filter((item) => {
                    return item.id !== id
                  });
                } else if (draft.items) {
                  draft.items = draft.items.filter((item: any) => {
                    return item.id !== id
                  });

                  if (draft.count) {
                    draft.count--
                  }
                } else {
                  delete draft[id]
                }
              }

              return draft
            })
          )
        )
      }
    } catch (e: unknown) {
      Logger.debug('Failed to update cache entries', e)

      patchResults.forEach((result) => result.undo())
    }

    return patchResults
  }
}

export default CacheManager