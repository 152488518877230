import { appApi, QueryStringParameters, ResponseObject, TagTypes, Timestamps } from "../../api";
import { Department } from "./department";
import { DepartmentRole } from "./role";
import { User } from "../user";
import { Member } from "../company/member";

export interface Person extends Timestamps {
  id: string;
  memberId: string;
  departmentId: string;
  roleId: string;
  appraisalTimescaleInDays?: number;
  member: Member;
  department?: Department;
  role: DepartmentRole;
}

export type PersonBody = Partial<
  Omit<
    Person,
    "id" | "memberId" | "member" | "department" | "role"
  >
>;

const peopleApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getPersonById: build.query({
      query: ({
        personId,
        params,
      }: {
        personId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `/people/${personId}`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Person) {
        return baseQueryReturnValue;
      },
      providesTags: (result, err, { personId }) => {
        if (!result) {
          return ["Department", "Person"];
        }

        return [{ type: "Person", id: personId }];
      },
    }),
    getPeopleByMember: build.query<Array<Person>, { memberId: string, params?: QueryStringParameters }>({
      query: ({
        memberId,
        params,
      }) => ({
        url: `member/${memberId}/people`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { memberId }) => {
        if (!result || result.length <= 0) {
          return ["Member", "Person"];
        }

        return result
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Person", id: item.id };
          })
          .concat({ type: "Member", id: memberId });
      },
    }),
    getCompetenceProgress: build.query<
      { percentage: number },
      { personId: string; params?: QueryStringParameters }
    >({
      query: ({ personId, params }) => ({
        url: `person/${personId}/module/progress`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { personId }) => {
        return [{ type: "Person", id: personId }];
      },
    }),
    getProgressByModule: build.query<
      { percentage: number },
      { personId: string; moduleId: string; }
    >({
      query: ({ personId, moduleId }) => ({
        url: `person/${personId}/module/${moduleId}/progress`,
        method: "GET",
      }),
      providesTags: (result, err, { personId }) => {
        return [{ type: "Person", id: personId }];
      },
    }),
    getPeopleByDepartment: build.query<ResponseObject<Person>, { departmentId: string, params?: QueryStringParameters }>({
      query: ({
        departmentId,
        params,
      }: {
        departmentId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `department/${departmentId}/people`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { departmentId }) => {
        if (!result || result.items.length <= 0) {
          return ["Department", "Person"];
        }

        return (result.items ?? [])
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Person", id: item.id };
          })
          .concat({ type: "Department", id: departmentId });
      },
    }),
    getPeopleByModule: build.query<ResponseObject<Person>, { moduleId: string, params?: QueryStringParameters }>({
      query: ({
        moduleId,
        params,
      }: {
        moduleId: string;
        params?: QueryStringParameters;
      }) => ({
        url: `module/${moduleId}/people`,
        method: "GET",
        params,
      }),
      providesTags: (result, err, { moduleId }) => {
        if (!result || result?.items.length <= 0) {
          return ["Module", "Person"];
        }

        return (result.items ?? [])
          .map<{ type: TagTypes; id: string }>((item) => {
            return { type: "Person", id: item.id };
          })
          .concat({ type: "Module", id: moduleId });
      },
    }),
    addPersonToModule: build.mutation({
      query: ({
        moduleId,
        personId,
      }: {
        moduleId: string;
        personId: string;
      }) => ({
        url: `module/${moduleId}/people/${personId}`,
        method: "PUT",
      }),
      transformResponse(baseQueryReturnValue: Person) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { personId, moduleId }) => [
        "Person",
        "Module",
      ],
    }),
    removePersonFromModule: build.mutation({
      query: ({
        moduleId,
        personId,
      }: {
        moduleId: string;
        personId: string;
      }) => ({
        url: `module/${moduleId}/people/${personId}`,
        method: "DELETE",
      }),
      transformResponse(baseQueryReturnValue: Person) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { personId, moduleId }) => [
        { type: "Person", id: personId },
        { type: "Module", id: moduleId },
      ],
    }),
    updatePerson: build.mutation({
      query: ({
        personId,
        body
      }: {
        personId: string;
        body: PersonBody
      }) => ({
        url: `people/${personId}`,
        method: "PATCH",
        body
      }),
      transformResponse(baseQueryReturnValue: Person) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { personId }) => [
        { type: "Person", id: personId },
      ],
    }),
    restorePerson: build.mutation({
      query: ({ personId }: { personId: string }) => ({
        url: `people/${personId}/restore`,
        method: "POST",
      }),
      transformResponse(baseQueryReturnValue: User) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { personId }) => [
        "Person",
      ],
    }),
    revokePerson: build.mutation({
      query: ({ personId }: { personId: string }) => ({
        url: `people/${personId}/revoke`,
        method: "POST",
      }),
      transformResponse(baseQueryReturnValue: User) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { personId }) => [
        "Person",
      ],
    }),
    deletePerson: build.mutation({
      query: ({ personId }: { personId: string }) => ({
        url: `person/${personId}`,
        method: "DELETE",
      }),
      transformResponse(baseQueryReturnValue: Person) {
        return baseQueryReturnValue;
      },
      invalidatesTags: (result, error, { personId }) => [
        { type: "Person", id: personId },
      ],
    }),
  }),
});

export const {
  useAddPersonToModuleMutation,
  useDeletePersonMutation,
  useGetPeopleByDepartmentQuery,
  useGetPeopleByModuleQuery,
  useGetPersonByIdQuery,
  useRemovePersonFromModuleMutation,
  useLazyGetPeopleByDepartmentQuery,
  useGetCompetenceProgressQuery,
  useGetPeopleByMemberQuery,
  useGetProgressByModuleQuery,
  useUpdatePersonMutation,
  useRestorePersonMutation,
  useRevokePersonMutation
} = peopleApi;

export default peopleApi;
