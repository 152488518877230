import { appApi, QueryStringParameters, TagTypes, Timestamps } from "../../api";

export const DepartmentRoleNames = ["Lead", "Assessor", "Candidate", "Revoked"] as const;

export type DepartmentRoles = (typeof DepartmentRoleNames)[number];

export type DepartmentRole = {
  id: string;
  name: "Lead" | "Assessor" | "Candidate" | "Revoked";
  precedence: number;
} & Timestamps;

export const DepartmentRolePrecedence = {
  Lead: 0,
  Assessor: 1,
  Candidate: 2,
  Revoked: 999
};

export class DepartmentRoleUtil {
  public static atLeast(precedence: number, comparison: number) {
    return precedence <= comparison;
  }

  public static atMost(precedence: number, comparison: number) {
    return precedence >= comparison;
  }
}

const departmentRoleApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getDepartmentRoles: build.query({
      query: ({ params }: { params?: QueryStringParameters }) => ({
        url: `department/role`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: Array<DepartmentRole>) {
        return baseQueryReturnValue;
      },
      providesTags: (result) => {
        return (result ?? []).map<{ type: TagTypes; id?: string }>((item) => {
          return { type: "DepartmentRole", id: item.id };
        }).concat({ type: 'DepartmentRole' });
      },
    }),
  }),
});

export const {
  useGetDepartmentRolesQuery,
  useLazyGetDepartmentRolesQuery,
} = departmentRoleApi;

export default departmentRoleApi;
