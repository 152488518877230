// React/Gatsby
import React from "react";

// Reach
import { Slice } from "gatsby";
import { Metadata, useGetMetadataQuery } from "../store/api/marketing/page";
import { SplashScreen } from "../components/common/SplashScreen";

export const MetadataContext = React.createContext<Metadata | undefined>(undefined)

const MarketingLayout = ({ children }: { children: React.ReactNode }) => {
  const metadata = useGetMetadataQuery({});

  if (metadata.isLoading) {
    return <SplashScreen />
  }

  return (
    <MetadataContext.Provider
      value={metadata.data?.data.attributes}
    >
      <main
        id="marketing-layout"
        className="flex flex-col flex-1 min-h-screen"
      >
        <Slice alias="marketing-header" />

        <div className="flex flex-col flex-1 relative">
          {children}

          <Slice alias="marketing-footer" />
        </div>
      </main>
    </MetadataContext.Provider>
  );
};

export default MarketingLayout;
