// FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faUser,
  faCog,
  faTimesCircle,
  faSpinner,
  faChevronCircleDown,
  faExternalLink,
  faChartLine,
  faCircleNodes,
  faUsers,
  faChevronRight,
  faChevronLeft,
  faTimes,
  faCheck,
  faPlus,
  faTrash,
  faExclamationCircle,
  faSignOut,
  faPencil,
  faMagnifyingGlass,
  faUsersRectangle,
  faGrid2,
  faChevronUp,
  faChevronDown,
  faFileLines,
  faCabinetFiling,
  faClipboardListCheck,
  faMemo,
  faEnvelope,
  faNotes,
  faEye,
  faEllipsisV,
  faSignature,
  faBadgeCheck,
  faBuilding,
  faPeople,
  faExchange,
  faX,
  faImage,
  faDownload,
  faQuestionCircle,
  faTick,
  faPaperPlane,
  faHourglass,
  faThumbsUp,
  faFeather,
  faComment,
  faHourglassEnd,
  faCircleExclamation,
  faHourglassClock,
  faExclamation,
  faCircleCheck,
  faArrowCircleRight,
  faTrashRestore,
  faRotateRight,
  faEyes,
  faIndustryWindows,
  faGrid,
  faTable,
  faChartUser,
  faBuildingUser,
  faCertificate,
  faChalkboardTeacher,
  faPersonChalkboard,
  faCircleXmark,
  faCopy,
  faArrowRight,
  faClock,
  faBullhorn,
  faBriefcase,
  faBold,
  faItalic,
  faStrikethrough,
  faParagraph,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faH6,
  faList,
  faListDots,
  faListNumeric,
  faCode,
  faBlockQuote,
  faHorizontalRule,
  faTurnDownLeft,
  faUndo,
  faRedo,
  faUnderline,
  faLink,
  faEraser,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faFloppyDisk,
  faFilter,
  faBarsFilter,
  faUserCrown,
  faBrowser,
  faBuildings,
  faEllipsisH,
  faUserPlus,
  faInfoCircle,
  faSignIn,
  faPenToSquare,
  faFilePlusMinus,
  faExchangeAlt,
  faUserMinus
} from "@fortawesome/pro-solid-svg-icons";

const FontAwesomePreloader = ({ ...props }) => {
  library.add(
    faBars,
    faUser,
    faCog,
    faTimesCircle,
    faSpinner,
    faChevronCircleDown,
    faExternalLink,
    faChartLine,
    faCircleNodes,
    faUsers,
    faChevronRight,
    faChevronLeft,
    faTimes,
    faCheck,
    faPlus,
    faTrash,
    faExclamationCircle,
    faSignOut,
    faPencil,
    faMagnifyingGlass,
    faUsersRectangle,
    faGrid2,
    faChevronUp,
    faChevronDown,
    faFileLines,
    faCabinetFiling,
    faClipboardListCheck,
    faMemo,
    faEnvelope,
    faNotes,
    faEye,
    faEllipsisV,
    faSignature,
    faBadgeCheck,
    faBuilding,
    faPeople,
    faExchange,
    faX,
    faImage,
    faDownload,
    faQuestionCircle,
    faTick,
    faPaperPlane,
    faHourglass,
    faThumbsUp,
    faFeather,
    faComment,
    faHourglassEnd,
    faCircleExclamation,
    faHourglassClock,
    faExclamation,
    faCircleCheck,
    faArrowCircleRight,
    faTimesCircle,
    faTrashRestore,
    faRotateRight,
    faEye,
    faEyes,
    faIndustryWindows,
    faGrid,
    faTable,
    faChartUser,
    faBuildingUser,
    faCertificate,
    faChalkboardTeacher,
    faPersonChalkboard,
    faCircleXmark,
    faCopy,
    faArrowRight,
    faClock,
    faBullhorn,
    faBriefcase,
    faBold,
    faItalic,
    faStrikethrough,
    faParagraph,
    faH1,
    faH2,
    faH3,
    faH4,
    faH5,
    faH6,
    faList,
    faListDots,
    faListNumeric,
    faCode,
    faBlockQuote,
    faHorizontalRule,
    faTurnDownLeft,
    faUndo,
    faRedo,
    faUnderline,
    faLink,
    faEraser,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faAlignJustify,
    faFloppyDisk,
    faFilter,
    faBarsFilter,
    faUserCrown,
    faBrowser,
    faBuildings,
    faEllipsisH,
    faUserPlus,
    faInfoCircle,
    faSignIn,
    faPenToSquare,
    faFilePlusMinus,
    faExchangeAlt,
    faUserMinus
  );

  return props.children;
};

export default FontAwesomePreloader;
