import React from "react";
import { useCookies } from "react-cookie";
import Logger from "../singletons/Logger";
import { useAppDispatch } from "../store";
import { appApi } from "../store/api";
import {
  Company,
  useGetCompanyQuery
} from "../store/api/company/company";
import {
  Member,
  useGetMembersByCompanyQuery,
} from "../store/api/company/member";
import AuthContext from "./AuthContext";
import { CompanyRolePrecedence, CompanyRoleUtil } from "../store/api/company/role";

export interface CompanyContext {
  company?: Company;
  member?: Member;
  setCompany: (company: Company) => void;
  atLeastAdmin: boolean
}

export const CompanyContext = React.createContext<CompanyContext>({
  setCompany: (company: Company) => {},
  atLeastAdmin: false
});

const CompanyProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, isLoggedIn } = React.useContext(AuthContext);
  const appDispatch = useAppDispatch();

  const [cookies, setCookie] = useCookies(["APP_SESSION", "APP_COMPANY"]);

  const [state, dispatch] = React.useReducer(
    (
      state: { company?: Company; member?: Member },
      action: {
        type: "setState";
        payload: { company?: Company; member?: Member };
      }
    ) => {
      if (action.type === "setState") {
        return {
          ...state,
          ...action.payload,
        };
      }

      throw new Error("Unknown action");
    },
    {
      company: undefined,
      member: undefined,
    }
  );

  const company = useGetCompanyQuery({
    companyId: cookies.APP_COMPANY
  }, { skip: !isLoggedIn() || !cookies.APP_COMPANY })

  const members = useGetMembersByCompanyQuery(
    {
      companyId: state.company?.id ?? "",
      params: {
        filter: {
          user: {
            id: {
              $equal: user!.getUsername()
            }
          },
        },
      },
    },
    { skip: !isLoggedIn() || !state.company?.id }
  );

  React.useEffect(() => {
    if (company.data) {
      dispatch({
        type: 'setState',
        payload: {
          company: company.data
        }
      })
    }

    if (company.isError || !company.data) {
      dispatch({
        type: 'setState',
        payload: {
          company: undefined,
          member: undefined
        }
      })
    }
  }, [company.data])

  React.useEffect(() => {
    Logger.debug('Company context members use effect', members.data)

    if (members.fulfilledTimeStamp) {
      dispatch({
        type: "setState",
        payload: {
          member: (members.data?.items ?? []).find(
            (member) => member.userId === user!.getUsername()
          ),
        },
      });
    }
  }, [members.data]);

  React.useEffect(() => {
    Logger.debug('APP_COMPANY cookie use effect', cookies.APP_COMPANY)

    if (!cookies.APP_COMPANY && (state.company || state.member)) {
      dispatch({
        type: "setState",
        payload: { company: undefined, member: undefined },
      });
    }
  }, [cookies.APP_COMPANY]);

  return (
    <CompanyContext.Provider
      value={{
        setCompany: (company: Company) => {
          setCookie('APP_COMPANY', company.id, { path: '/' })

          dispatch({ type: "setState", payload: { company } });

          appDispatch(appApi.util.invalidateTags(["Company"]));
        },
        company: state.company,
        member: state.member,
        atLeastAdmin: CompanyRoleUtil.atLeast(state.member?.role.precedence ?? CompanyRolePrecedence.Revoked, CompanyRolePrecedence.Admin)
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyProvider;
